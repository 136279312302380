
const list = () => {
  return(
    [
      {
        title: 'About Us',
        list: {
          // "/about" : '楽単アルプスとは？',
          "/report": '開発レポート',
        }
      },
      {
        title: "SNS",
        list: {
          'https://twitter.com/rakutan_alps': 'Twitter',
        }
      },
      {
        title: 'お問合せ',
        list: {
          "https://forms.gle/hVQCJs2DnuCvqP788": 'お問い合わせ口',
          "https://forms.gle/LuWTboKg8Dn57wdn7": '改善要望・ご意見窓口'
        }
      },
      {
        title: '規約・ポリシー',
        list: {
          "/terms": '利用規約',
          "/privacy" : 'プライバシーポリシー'
        }
      }
    ]
  )
}

export default list;