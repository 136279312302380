import React , { useState, useCallback, useEffect, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import CommentIcon from '@mui/icons-material/Comment';
import FolderIcon from '@mui/icons-material/Folder';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { navigate } from 'gatsby';
import AccountBoxIcon from '@mui/icons-material/AccountBox';



const AutoCompleteList = props => {


  const handleOnClick = e => {
    console.log(props);

    props.onClose();
    props.setInputValue(props.name);
    navigate('/lessons/?query='+encodeURIComponent(props.name), {
      state: {
        query: props.name,
        page: 1,
      }
    });
  }

  const addName = props.addName !== undefined ? props.addName : '';

  return (
    <ListItemButton onClick={handleOnClick}>
    <ListItem>

      {
        props.icon === 'folder' &&
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
      }
      {
        props.icon === 'accountBox' &&
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
      }

    <ListItemText
      primary={props.name + addName}
      secondary={props.subname}
    />

    </ListItem>
    </ListItemButton>
  )
}


export default function MyApp (props) {

  const close = props.onClose;
  const value = props.value;
  let teachers = props.res.teachers
  let lessons = props.res.lessons;
  if  (teachers === undefined) {
    teachers = []; lessons = [];
  }

  return(
    <>

      <AutoCompleteList setInputValue={props.setInputValue} name={value} onClose={close} addName={" を検索"} style={{paddingLeft: 8}}/>


      {
        props.isLoading &&
        <>
          {/* <CircularProgress /> */}
        </>

      }
        <>
      
          {
            lessons.length !== 0 &&
            <List 
              dense={false}
              subheader={
                <Typography variant="subtitle2" sx={{paddingTop: '1rem'}}>
                  {/* <LocalOfferIcon fontSize="small" /> */}
                  # 科目名
                </Typography>
              }
            >
              {lessons.map((obj, index) => {
                return <AutoCompleteList setInputValue={props.setInputValue} name={obj.sub_name} onClose={close} icon="folder" key={index}/>
              })}
            </List>
          }
          { 
            teachers.length !== 0 &&
            <List 
              dense={false}
              subheader={
                <Typography variant="subtitle2" sx={{paddingTop: '1rem'}}>
                  {/* <LocalOfferIcon fontSize="small" /> */}
                  # 教員名
                </Typography>
              }
            >
              {teachers.map((obj, index) => {
                return <AutoCompleteList name={obj.teacher} setInputValue={props.setInputValue} onClose={close} icon="accountBox" key={index}/>
              })}
            </List>
          }

        </>

    </>
  )
}


