import React from "react";
import { Footer } from "./Footer"
import { Header } from "./Header"

const layout = ({children}) => {


    return (
        <>
            <Header />
                {children}
            <Footer />
        </>
    )
}

export default layout