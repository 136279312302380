import React, {useEffect, useState} from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer"; // 1.9K gzipped
import { useTheme } from "@mui/material/styles";
import { Progress } from 'semantic-ui-react'
import { Box, Typography } from "@mui/material";
// import 'semantic-ui-css/semantic.min.css'
import './progressBar.css'


export default function (props) {  
  const theme = useTheme(); 

  const {
    value, total
  } = props;

  const percent = (100*(value/total)).toFixed(1)

  return (
    
    <Box sx={{
      "& .bar, .bar .progress ": {
        backgroundColor: theme.palette.secondary.main + "!important",
      },
      ".progress": {
        height: "auto"
      },
      "& .ui.active.inverted.progress .label": {
        color: theme.palette.text.primary,
      },
      "& .ui.active.inverted.progress": {
        backgroundColor: theme.palette.grey[300],
        my: 0,
      }

    }}>

      <Progress percent={percent} inverted progress active />
      <Typography variant="subtitle1" textAlign={"center"} fontWeight={"600"}>
        現在のレビュー数{value}件／目標{total}件
      </Typography>

    </Box>
  )
}