import React , { useState, useCallback, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled, alpha } from '@mui/material/styles';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';

import axios from "axios";
import { navigate } from 'gatsby';
import { SearchList, SearchBoxHome, SearchBox} from './';
import { htmlspecialchars } from 'function/common';

const searchKeywordName = 's';


const SearchBotton = styled(Button)(({ theme }) => ({
  // color: theme.palette.primary.contrastText,
  // backgroundColor: rgb(229, 232, 236),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

export default function IconLabelButtons() {
  const theme = useTheme();
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let prevValue = '';
  const [inputValue, setInputValue] = useState(prevValue);
  const [keywordsList, setKeywordsList] = useState({});
  const [placeholder, setPlaceHolder] = useState({})

  const handleOpen = useCallback(() => {
    setOpen(true);
  },[open],)
  const handleClose = useCallback(() => {
    setOpen(false);
  },[open],)
  const handleInputValue = useCallback((e) => {
    setInputValue(e.target.value);
    getPostsData(e.target.value);
  },[inputValue],)

  const handleSubmit = e => {
    e.preventDefault();
    if (inputValue.trim() == "") {
      navigate('/lessons');
    } else {
      navigate('/lessons/?query='+encodeURIComponent(inputValue), {
        state: {
          query: inputValue,
          page: 1,
        }
      });
    }
    // setInputValue('');
    handleClose();
  }

  useEffect(() => {
    if (placeholder.word === inputValue) {
      setKeywordsList(placeholder.data);
    }
    console.log(placeholder);
  }, [placeholder]);
  

  const getPostsData = async(val) => {
    // const postValue = inputValue.trim();
    const postValue = val.trim();
    if (postValue.length > 80) return;
    setIsLoading(true);
    axios.post('https://api.rakutan-alps.com/api/lessons/word/',{
      value: postValue
    }).then(res => {
      setIsLoading(false);
      setPlaceHolder(res.data);
    })
  } 


  
  const isOverSm = useMediaQuery(theme.breakpoints.up('sm')); // 600px
  const isOverMd = useMediaQuery(theme.breakpoints.up('md')); // 900px

  return (
    <>
    <Stack direction="row" spacing={2}>
      <SearchBotton
        // variant="outlined"
        variant="contained"
        color="secondary"
        endIcon={<SearchIcon />}
        onClick={handleOpen}
        inputProps={{
          inputMode: "search"
        }}
      >
        検索
      </SearchBotton>
    </Stack>



    <Dialog
      open={open}
      fullScreen={!isOverMd}
      scroll="paper"
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={150}
      maxWidth="md"
      sx={{
        borderRadius: 10,
      }}
    > 
      <DialogTitle
        id="alert-dialog-title"
        component="form"
        onSubmit={handleSubmit}
        action="/lessons"
        method="get"
        sx={{ p: '10px 4px', display: 'flex', alignItems: 'center'}}
      > 
        <IconButton sx={{ p: '9px',mr: 1 }} aria-label="close" onClick={handleClose} >
          <ArrowBackIosIcon />
        </IconButton>

        <InputBase
          sx={{fontSize: '1.2rem', }}
          autoFocus
          margin="dense"
          inputProps={{ 'aria-label': 'キーワード検索' , inputMode: "search"}}
          fullWidth
          variant="standard"
          name={searchKeywordName}
          defaultValue={inputValue}
          onInput={handleInputValue}
          autoComplete="off"
          enterKeyHint="search"
          id="inputText"
        />

        <Button variant="outlined"
          type="submit"
          endIcon={<SearchIcon />}
          sx={{ width: '8rem', mr: 1}} aria-label="search"
        >
          検索
        </Button>
      </DialogTitle>

      <Divider/>

      <DialogContent sx={{paddingY: .5, height: "60vh"}}>
        {
          inputValue.trim() == "" ?
          <SearchBoxHome /> :
          // <></>
          <SearchList res={keywordsList} value={inputValue} isLoading={isLoading} onClose={handleClose} setInputValue={setInputValue} />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>戻る</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}