import React from "react";
import { styled } from "@mui/material/styles";

const H6 = styled('h6')(({ theme }) => ({
  borderWidth: 0,
  borderLeftWidth: 3,
  borderStyle: 'solid',
  borderColor: theme.palette.secondary.light,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary
}));

const Minititle = props => {
  
  return (
    <H6 {...props}>
      {props.children}
    </H6>
  )
}

export default Minititle;