import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';


export default function (props) {

  return (
    <Typography
      sx={theme => ({
        position: "sticky",
        top: 80,
        p: 2,
        px: 3,
        my: 2,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#6cc7ff",
        // boxShadow: theme.shadows[5],
        ...props.sx
        // backgroundColor: theme.palette.primary.light
      })}
      variant='h6'
    >
      {props.children}
      
    </Typography>
  )  

}