import * as React from 'react';
import {useState, useCallback, useEffect} from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/styles';

import { Link } from 'gatsby';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from 'context/';

import {
  Inbox as InboxIcon ,
  Mail as MailIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  FindInPage as FindInPageIcon,
  RateReview as RateReviewIcon,
  Feedback as FeedbackIcon,
  History as HistoryIcon,
  Bookmarks as BookmarksIcon,
  Balance as BalanceIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
} from '@mui/icons-material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HelpIcon from '@mui/icons-material/Help';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function SwipeableTemporaryDrawer() {
  const [isOpen, setIsOpen] = useState(false);

  const openning = useCallback(event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) { return }
    setIsOpen(true)
  },[setIsOpen])

  const closing = useCallback(event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) { return }
    setIsOpen(false)
  },[setIsOpen])

  const MenuList = props => {

    return(
      <>
      <Link
        to={props.url}
        onClick={closing}
        style={{textDecoration: "none"}}
      >
      <ListItem button key={props.content} sx={{color: "text.primary"}}>
        <ListItemIcon>
          {props.children}
        </ListItemIcon>
        <ListItemText primary={<>
          <span style={{fontSize: "1.0rem"}} >{props.content}</span>
          <span style={{marginLeft: 8}}>{props.subContent}</span>
        </>}
          secondary={props.sub}
        />
      </ListItem>
      </Link>
      </>
    )
  }

  const DisableMenuList = props => {
    return(
      <>
      <ListItem button key={props.content} sx={{color: "text.primary"}}>
        <ListItemIcon>
          {props.children}
        </ListItemIcon>
        <ListItemText primary={<>
          <span style={{fontSize: "1.0rem"}} >{props.content}</span>
          <span style={{marginLeft: 8}}>{props.subContent}</span>
        </>}
          secondary={props.sub}
        />
      </ListItem>
      </>
    )
  }

  const ToggleColorMode = props => {

    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);

    const toggles = async () => {
      await colorMode.toggleColorMode()
    }
    let mode = theme.palette.mode === 'light' ? 'ダーク' : 'ライト'
        mode += 'モード'
    // const mode

    return(
      <>
      <ListItem button key={props.content} onClick={toggles}>
        <ListItemIcon>
          {theme.palette.mode === 'light' ?
           <DarkModeIcon /> : <LightModeIcon />
          }
        </ListItemIcon>
        <ListItemText primary={<span style={{fontSize: "1.0rem"}} >{mode}</span>}/>
      </ListItem>
      </>
    )
  }


  function DrwaerMenu() {
    return (
      <>
        <Divider />

        <List>

          <MenuList content="楽単アルプスTOP" url="/">
            <HomeIcon />
          </MenuList>

          <MenuList content="レビューを見る" url="/lessons">
            <FindInPageIcon />
          </MenuList>

          <DisableMenuList content="授業比較をする" subContent="" sub="近日公開予定" url="/Comparison" disable>
            <BalanceIcon />
          </DisableMenuList>

        </List>
        <Divider />
        <List>

          <MenuList content="ブックマーク" url="/history?v=2">
            <BookmarksIcon />
          </MenuList>
          
          <MenuList content="閲覧履歴" url="/history?v=1">
            <HistoryIcon />
          </MenuList>

          <ToggleColorMode content="ダークモード">
            <DarkModeIcon />
          </ToggleColorMode>

        </List>

        <Divider />

        <List>

          {/* <MenuList content="楽単アルプスとは" url="/about">
            <HelpIcon />
          </MenuList> */}

          {/* <MenuList content="開発レポート" url="/report">
            <HistoryEduIcon />
          </MenuList> */}
          

        </List>


      </>
    )
  }



  return (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ ml: 2 }}
        onClick={openning}
      >
        <MenuIcon />
      </IconButton>
      
      <SwipeableDrawer
        anchor={"right"}
        open={isOpen}
        onClose={closing}
        onOpen={openning}
        transitionDuration={{
          appear: 4500,
          enter: 450,
          exit: 450,
        }}
        // style={{
        //   transitioTimingFunction: "step-start !important",
        // }}

      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={closing}
          onKeyDown={closing}
        >
          <DrawerHeader>
            <IconButton onClick={closing}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>

        </Box>

        <DrwaerMenu toggle={closing}/>
      </SwipeableDrawer>
    </div>
  );
}
