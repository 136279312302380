import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Spinner from 'react-bootstrap/Spinner';


export default function Animations(props) {
  return (
    <Grid item xs={12} sx={{
      height: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }} {...props}>
      <Spinner animation="border" variant="primary" />
    </Grid>
  );
}