import React from "react";
import { Grid } from "@mui/material";

const ContainerItem = (props) => {
  return (
    <Grid item xs={11} {...props} >
      {props.children}
    </Grid>
  )
}

export default ContainerItem;