import React from "react";
import { Grid } from "@mui/material";

const Container = (props) => {
  return (
    <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center"
      {...props} 
    >
      {props.children}
    </Grid>
  )
}

export default Container;