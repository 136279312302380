import React from "react";
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';

import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';

import { styled } from '@mui/material/styles';
import { MiniTitle } from "components/UIkit";
import { Link } from "gatsby"

const ListItem = props => {

  // props.list obj

  return(
    <>
      <Typography variant="p">
        <MiniTitle sx={{mb: 1, fontSize: '1.3rem'}}>
          {props.title}
        </MiniTitle>

        <MuiList dense>

          {
            Object.keys(props.list).length===0 && 
              <MuiListItem sx={(theme) => ({
                color: theme.palette.grey[200]
              })}>
                準備中...
              </MuiListItem>
          }

          {Object.keys(props.list).map((key, index) => {

            return (
              <MuiListItem 
                key={index}
                sx={(theme) => ({
                  color: theme.palette.text.secondary
                })}
              >

                { /^(http)/.test(key) ? 
                  <a href={key} style={{color: "inherit", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                    {props.list[key]}
                  </a>
                  :
                  <Link to={key} style={{color: "inherit", textDecoration: "none" }}>
                    {props.list[key]}
                  </Link>
                }

              </MuiListItem>
            )
          })}
        </MuiList>

      </Typography>
    </>
  )
}

export default function Footer (props) {

  const list = props.list;

  return (
    <>
      <Grid container sx={{mt: 3}}>
        {list.map((items, index) => {
          return(
            <Grid item xs={12} sm={6} md={6} lg={3} key={index} sx={{mt: 2}}>
              <ListItem list={items.list} title={items.title}/>
            </Grid>
          )
        })}

      </Grid>
    </>
  )
}