import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {ScrollTop, Drawer,SearchBox} from "./";
import { navigate } from 'gatsby';
import { IconButton } from '@mui/material';
import logo from 'assets/img/icons/logo.png'



export default function SearchAppBar() {
  return (
    <>
    <Box sx={{ flexGrow: 1 ,}}>
      <AppBar sx={{bg: "primary.main"}}>
        <Toolbar sx={{bg: "primary.main"}}>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ cursor: 'pointer', pr: 4}}
            onClick={(e) => {navigate("/");}}
          >

            <img src={logo} height={"64"}/>

          </Typography>
          <Box sx={{ flexGrow: 1 }}>
          </Box>


          <SearchBox />
          
          <Drawer />

        </Toolbar>
      </AppBar>
    </Box>
      <Toolbar id="back-to-top-anchor" sx={{height: 64, mb: 2}} />

      <ScrollTop />
    </>
  );
}