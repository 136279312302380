import React from "react";
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { Container, ContainerItem } from "components/UIkit";
import logo from 'assets/img/icons/logo.png'
import { List, ListText } from ".";
import Divider from 'components/UIkit/Divider'
import { styled } from '@mui/material/styles';
import { navigate } from "gatsby";

const Margin5 = styled('div')(({ theme }) => ({
  margin: theme.spacing(2.5),
}));



export default function Footer (props) {

  const list = ListText();
  return (
    <>
    <Margin5 />
    <Divider />
    <Container
      component='footer'
      sx={(theme) => ({
        pt: 2,
        // px: [2, 2, 4]
        bgcolor: theme.palette.background.default,
        
      })}
    >
      <ContainerItem item xs={11}>


        <List list={list} />

        <Grid container
          // direction="row-reverse"
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{pb: 2}}
        >
          <Grid item xs={12} md={12} >
            <Typography variant="h5" onClick={() => navigate("/")} sx={{py:2, cursor: "pointer", display:"flex"}} >
              <img src={logo} height={"92"} style={{marginLeft: "auto", marginRight: "auto"}}/>
            </Typography>
          </Grid>

        </Grid>

      <Divider />


      <Typography variant="subtitle1" textAlign={"center"} sx={{p:3, fontSize: ".8rem"}} color={"GrayText"} >
        Copyright © 2022 楽単アルプス All Rights Reserved.
      </Typography>


      </ContainerItem>
    </Container>
    </>
  )
}