// import {storage} from "../firebase/index";
import React from "react";
import { useLocation } from "@reach/router";
import HTMLReactParser from "html-react-parser";

/**
 * When user select an image file from his local directory, upload it to Firebase Storage, get download URL,
 * and set the URL to the src property of img tag for displaying the thumbnail.
 * @param {string} id The identifier of input tag for uploading files
 */


// export const attachFiles = (id, type) => {
//     if (type === 'remove') {
//         return document.getElementById(id).removeEventListener('change', () => null);
//     } else if (type === 'add') {
//         document.getElementById(id).addEventListener("change", (event)=> {
//             const file = event.target.files;
//             // @ts-ignore
//             let blob = new Blob(file, { type: "image/jpeg" });

//             // Generate random 16 digits strings
//             const S="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
//             const N=16;
//             const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n)=>S[n%S.length]).join('')

//             const uploadRef = storage.ref('images').child(fileName);
//             const uploadTask = uploadRef.put(blob);
//             uploadTask.on('state_changed', (snapshot) => {
//                 // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//                 let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 console.log('Upload is ' + progress + '% done');
//             }, (error) => {
//                 // Handle unsuccessful uploads
//                 console.error("Failed to upload file. ERROR: ", error);
//             }, () => {
//                 // Handle successful uploads on complete
//                 uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
//                     console.log('File available at', downloadURL);
//                     document.getElementById(`${id}-thumb`).setAttribute('src', downloadURL);
//                 });
//             });
//         });
//     }

// };


/**
 * Convert Carriage Return and Line Feed into <br> tag.
 * @param {string} text The row text
 * @returns {void | string | never} The formatted text
 */
export const nl2br = (text) => {
    if (text === "") {
        return text
    } else {
        return HTMLReactParser(text.replace(/\r?\n/g, '<br/>'))
    }
};


/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
// export const dateToString = (dt) => {
//     return dt.getFullYear() + '-'
//         + ('00' + (dt.getMonth()+1)).slice(-2) + '-'
//         + ('00' + dt.getDate()).slice(-2)
// };


/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
// export const datetimeToString = (dt) => {
//     return dt.getFullYear() + '-'
//         + ('00' + (dt.getMonth()+1)).slice(-2) + '-'
//         + ('00' + dt.getDate()).slice(-2) + ' '
//         + ('00' + dt.getHours()).slice(-2) + ':'
//         + ('00' + dt.getMinutes()).slice(-2) + ':'
//         + ('00' + dt.getSeconds()).slice(-2)
// };


/**
 * Validate input email
 * @param email
 * @returns {boolean}
 */
// export const isValidEmailFormat = (email) => {
//     const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
//     return regex.test(email)
// }


/**
 * Show an alert if required input is blank
 * @param args Required input values
 * @returns {boolean}
 */
// export const isValidRequiredInput = (...args) => {
//     let validator = true;
//     for (let i=0; i < args.length; i=(i+1)|0) {
//         if (args[i] === "") {
//             validator = false;
//         }
//     }
//     return validator
// };



// マージ用の再帰的関数
// export const mergeObj = (x1, x2) => {
//     // (Arrayでない)Objectの判定
//     const isObject = obj => (obj instanceof Object && !(obj instanceof Array));
//     let mg = {...x1, ...x2};
//     for (const key in mg) {
//         if (key in x2) {
//             if (isObject(mg[key])) {
//                 if ( x1[key] === undefined ) {
//                     mg[key] = x2[key];
//                 } else {
//                     mg[key] = mergeObj(x1[key], x2[key]);
//                 }
//             } else if (Array.isArray(mg[key])) {
//                 mg.key = mergeObj(x1[key], x2[key]);;
//             }
//         }
//     }
//     return mg;
// }

export const isInt = str => {
    if (str == '0') return false;
    var pattern = /^([1-9]\d*|0)$/;
    return pattern.test(str);
}

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function createParam(params = {}) {
    const queries = new URLSearchParams(params);
    return  queries.toString();
}
  

export function htmlSpecialChars_decode (encodedString) {
    let entity_char_obj = {
        '&quot;': '"',
        '&amp;': '&',
        '&sol;': '/',
        '&lt;': '<',
        '&gt;': '>',
        '&sbquo;': '‚',
        '&bdquo;': '„',
        '&dagger;': '†',
        '&Dagger;': '‡',
        '&permil;': '‰',
        '&lsaquo;': '‹',
        '&lsquo;': '‘',
        '&rsquo;': '’',
        '&ldquo;': '“',
        '&rdquo;': '”',
        '&trade;': '™',
        '&rsaquo;': '›',
        '&nbsp;': ' ',
        '&iexcl;': '¡',
        '&cent;': '¢',
        '&pound;': '£',
        '&curren;': '¤',
        '&yen;': '¥',
        '&brvbar;': '¦',
        '&sect;': '§',
        '&uml;': '¨',
        '&copy;': '©',
        '&ordf;': 'ª',
        '&laquo;': '«',
        '&not;': '¬',
        '&shy;': '­',
        '&reg;': '®',
        '&macr;': '¯',
        '&deg;': '°',
        '&plusmn;': '±',
        '&sup2;': '²',
        '&sup3;': '³',
        '&acute;': '´',
        '&micro;': 'µ',
        '&para;': '¶',
        '&middot;': '·',
        '&cedil;': '¸',
        '&sup1;': '¹',
        '&ordm;': 'º',
        '&raquo;': '»',
        '&frac14;': '¼',
        '&frac12;': '½',
        '&frac34;': '¾',
        '&iquest;': '¿',
        '&Agrave;': 'À',
        '&Aacute;': 'Á',
        '&Acirc;': 'Â',
        '&Atilde;': 'Ã',
        '&Auml;': 'Ä',
        '&Aring;': 'Å',
        '&AElig;': 'Æ',
        '&Ccedil;': 'Ç',
        '&Egrave;': 'È',
        '&Eacute;': 'É',
        '&Ecirc;': 'Ê',
        '&Euml;': 'Ë',
        '&Igrave;': 'Ì',
        '&Iacute;': 'Í',
        '&Icirc;': 'Î',
        '&Iuml;': 'Ï',
        '&ETH;': 'Ð',
        '&Ntilde;': 'Ñ',
        '&Ograve;': 'Ò',
        '&Oacute;': 'Ó',
        '&Ocirc;': 'Ô',
        '&Otilde;': 'Õ',
        '&Ouml;': 'Ö',
        '&times;': '×',
        '&Oslash;': 'Ø',
        '&Ugrave;': 'Ù',
        '&Uacute;': 'Ú',
        '&Ucirc;': 'Û',
        '&Uuml;': 'Ü',
        '&Yacute;': 'Ý',
        '&THORN;': 'Þ',
        '&szlig;': 'ß',
        '&agrave;': 'à',
        '&aacute;': 'á',
        '&acirc;': 'â',
        '&atilde;': 'ã',
        '&auml;': 'ä',
        '&aring;': 'å',
        '&aelig;': 'æ',
        '&ccedil;': 'ç',
        '&egrave;': 'è',
        '&eacute;': 'é',
        '&ecirc;': 'ê',
        '&euml;': 'ë',
        '&igrave;': 'ì',
        '&iacute;': 'í',
        '&icirc;': 'î',
        '&iuml;': 'ï',
        '&eth;': 'ð',
        '&ntilde;': 'ñ',
        '&ograve;': 'ò',
        '&oacute;': 'ó',
        '&ocirc;': 'ô',
        '&otilde;': 'õ',
        '&ouml;': 'ö',
        '&divide;': '÷',
        '&oslash;': 'ø',
        '&ugrave;': 'ù',
        '&uacute;': 'ú',
        '&ucirc;': 'û',
        '&uuml;': 'ü',
        '&yacute;': 'ý',
        '&thorn;': 'þ',
        '&yuml;': 'ÿ',
        '&fnof;': 'ƒ',
        '&Alpha;': 'Α',
        '&Beta;': 'Β',
        '&Gamma;': 'Γ',
        '&Delta;': 'Δ',
        '&Epsilon;': 'Ε',
        '&Zeta;': 'Ζ',
        '&Eta;': 'Η',
        '&Theta;': 'Θ',
        '&Iota;': 'Ι',
        '&Kappa;': 'Κ',
        '&Lambda;': 'Λ',
        '&Mu;': 'Μ',
        '&Nu;': 'Ν',
        '&Xi;': 'Ξ',
        '&Omicron;': 'Ο',
        '&Pi;': 'Π',
        '&Rho;': 'Ρ',
        '&Sigma;': 'Σ',
        '&Tau;': 'Τ',
        '&Upsilon;': 'Υ',
        '&Phi;': 'Φ',
        '&Chi;': 'Χ',
        '&Psi;': 'Ψ',
        '&Omega;': 'Ω',
        '&alpha;': 'α',
        '&beta;': 'β',
        '&gamma;': 'γ',
        '&delta;': 'δ',
        '&epsilon;': 'ε',
        '&zeta;': 'ζ',
        '&eta;': 'η',
        '&theta;': 'θ',
        '&iota;': 'ι',
        '&kappa;': 'κ',
        '&lambda;': 'λ',
        '&mu;': 'μ',
        '&nu;': 'ν',
        '&xi;': 'ξ',
        '&omicron;': 'ο',
        '&pi;': 'π',
        '&rho;': 'ρ',
        '&sigmaf;': 'ς',
        '&sigma;': 'σ',
        '&tau;': 'τ',
        '&upsilon;': 'υ',
        '&phi;': 'φ',
        '&chi;': 'χ',
        '&psi;': 'ψ',
        '&omega;': 'ω',
        '&thetasym;': 'ϑ',
        '&upsih;': 'ϒ',
        '&piv;': 'ϖ',
        '&bull;': '•',
        '&hellip;': '…',
        '&prime;': '′',
        '&Prime;': '″',
        '&oline;': '‾',
        '&image;': 'ℑ',
        '&weierp;': '℘',
        '&real;': 'ℜ',
        '&alefsym;': 'ℵ',
        '&larr;': '←',
        '&uarr;': '↑',
        '&rarr;': '→',
        '&darr;': '↓',
        '&harr;': '↔',
        '&crarr;': '↵',
        '&lArr;': '⇐',
        '&uArr;': '⇑',
        '&rArr;': '⇒',
        '&dArr;': '⇓',
        '&hArr;': '⇔',
        '&forall;': '∀',
        '&part;': '∂',
        '&exist;': '∃',
        '&empty;': '∅',
        '&nabla;': '∇',
        '&isin;': '∈',
        '&notin;': '∉',
        '&ni;': '∋',
        '&prod;': '∏',
        '&sum;': '∑',
        '&minus;': '−',
        '&lowast;': '∗',
        '&radic;': '√',
        '&prop;': '∝',
        '&infin;': '∞',
        '&ang;': '∠',
        '&and;': '∧',
        '&or;': '∨',
        '&cap;': '∩',
        '&cup;': '∪',
        '&int;': '∫',
        '&there4;': '∴',
        '&sim;': '∼',
        '&cong;': '≅',
        '&asymp;': '≈',
        '&ne;': '≠',
        '&equiv;': '≡',
        '&le;': '≤',
        '&ge;': '≥',
        '&sub;': '⊂',
        '&sup;': '⊃',
        '&nsub;': '⊄',
        '&sube;': '⊆',
        '&supe;': '⊇',
        '&oplus;': '⊕',
        '&otimes;': '⊗',
        '&perp;': '⊥',
        '&sdot;': '⋅',
        '&lceil;': '⌈',
        '&rceil;': '⌉',
        '&lfloor;': '⌊',
        '&rfloor;': '⌋',
        '&lang;': '〈',
        '&rang;': '〉',
        '&loz;': '◊',
        '&spades;': '♠',
        '&clubs;': '♣',
        '&hearts;': '♥',
        '&diams;': '♦'
    }
    function makeMatchSentence () {
        let regexString = '';
        for (var key in entity_char_obj) {
            let entity = key;
            regexString += entity + '|';
        }
        regexString = regexString.substring(0, regexString.length - 1);
        let regex = new RegExp(regexString, 'g');
        return regex;
    }
    function htmlSpecialChars_decoder (encodedString) {
        let htmlSpecialCharsRegex = makeMatchSentence ();
        let matches = encodedString.match(htmlSpecialCharsRegex);
        let decodedString = encodedString;
        matches.forEach(function (matchedChar, index) {
            let correspondingEntity = entity_char_obj[matchedChar];
            let regex = new RegExp(matchedChar, 'g');
            decodedString = decodedString.replace(regex, correspondingEntity);
        })
        return decodedString;
    }
    return htmlSpecialChars_decoder(encodedString);
}

export function htmlspecialchars(str){
  return (str + '').replace(/&/g,'&amp;')
                   .replace(/"/g,'&quot;')
                   .replace(/'/g,'&#039;')
                   .replace(/</g,'&lt;')
                   .replace(/>/g,'&gt;'); 
}