import React from "react";
import { Paper as MuiPaper} from "@mui/material";
import { styled } from "@mui/material/styles";

const Paper = styled(MuiPaper)(({ theme }) => ({
  borderRadius: theme.shape.paperBorderRadius
}));

export default function (props) {
  return <Paper elevation={4} {...props} >{props.children}</Paper>
}