// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let firebaseConfig = {
  apiKey: "AIzaSyAckcgdW7kBL7xa0PH3plqjt7oNKZI0FEk",
  authDomain: "rakutan-alps.firebaseapp.com",
  databaseURL: "https://rakutan-alps-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "rakutan-alps",
  storageBucket: "rakutan-alps.appspot.com",
  messagingSenderId: "512880481777",
  appId: "1:512880481777:web:ab65ece09dde7a2a26e050",
  measurementId: "G-DY6RNE54R1"
}; // 本番用

if (process.env.NODE_ENV === "development") firebaseConfig = {
  apiKey: "AIzaSyAr7ZbtC-zFc2cDBNx-EJlqa0IC6NJySx8",
  authDomain: "rakutan-alps-analytics.firebaseapp.com",
  projectId: "rakutan-alps-analytics",
  storageBucket: "rakutan-alps-analytics.appspot.com",
  messagingSenderId: "529503335115",
  appId: "1:529503335115:web:5ba8411d6e529475e5a14e",
  measurementId: "G-M1T8FMT062"
}; // テスト用

const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);


export default app;
export const db = getFirestore(app);
