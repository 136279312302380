import * as React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import smoothscroll from 'smoothscroll-polyfill';




function ScrollTop(props) {
  const { children, window } = props;
  
  const trigger = useScrollTrigger({
    target: (typeof window !== 'undefined' ) ? window : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  React.useEffect(() => {
    smoothscroll.polyfill();
    // setTrigger();
  }, []);

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        // block: 'center',
        block: 'start'
      });
    }
  };
  return (
    <Zoom in={trigger} style={{zIndex: 80}}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 32, right: 32 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


export default function BackToTop(props) {
  return (
    <React.Fragment>
      <ScrollTop {...props}>
        <Fab
         color="secondary" size="small" aria-label="scroll back to top"
         sx={{width: 56, height: 56}}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
