import React from "react";
import { Divider } from "@mui/material";

const StyledDivider = props => {

  return (
    <Divider 
      {...props} 
      sx={{
      width: '100%',
      borderWidth: '0px 0px thin !important',
      ...props.sx
    }}/>
  )
}

export default StyledDivider;