import React, { useEffect, useState, useCallback} from "react";
import { PaperBox } from ".";
import { 
  getFirestore,
  collection, 
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import "../../config/firebaseConfig";
import { Fade, IconButton, Typography } from "@mui/material";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import OutlinedInput from '@mui/material/OutlinedInput';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send';
import Button from "@mui/material/Button";
import { Snackbar } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useLocation } from "@reach/router";



const FeedIcon = props => {

  const [clickValue, setClickValue] = useState()

  const handleClick = type => {
    props.onChange(type);
    setClickValue(type);
  }

  const onClickColor = clickValue ? {
    good: {color: "#007FFF"},
    bad: {color: "rgb(235, 0, 20)"}
  } : {
    good: {},
    bad: {}
  }
  return (
    <IconButton onClick={() => {handleClick(props.type)}} disabled={props.disabled} sx={{mx: 1}}>
      { props.type === "good" &&
        <ThumbUpAltIcon
          sx={{...onClickColor.good}}
        />
      } {
        props.type === "bad" &&
        <ThumbDownAltIcon
          sx={{...onClickColor.bad}}
        />
      }
    </IconButton>
  )
}


export default function (props) {

  const db = getFirestore();
  const location = useLocation();
  

  const [feedbackValue, setFeedbackValue] = useState("");
  const [inputValue, setInputValue] = useState("")
  const [isPosted, setIsPosted] = useState(false);
  const [isPostting, setIsPostting] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("ありがとうございます！")
  const disabled = feedbackValue === "" ? false : true;


  useEffect(() => {
    if(isPosted) {
      setSnackOpen(true);
      setTimeout(() => {
        setSnackOpen(false)
      }, 3000);
    }
  }, [isPosted])

  useEffect(() => {
    if(feedbackValue !== "") {
      addDoc(collection(db, "good_or_bad"), {

        評価: feedbackValue,
        場所: location.href,
        timestamp: serverTimestamp(),
      
      }).then(res => {
        
      })
    }
  }, [feedbackValue])

  const fetch = (myname) => {
    return new Promise((resolve, reject) => {
      if (myname) {
        setTimeout(() => {
          
          resolve(`文字列「${myname}」の長さは${myname.length}です`);
        }, 2000);
      } else {
        reject("文字列が入力されていません");
      }
    })
  }

  const handleInputValue = useCallback( e => {
    setInputValue (e.target.value);
  },[inputValue])  

  const postFeedBack = () => {

    if (feedbackValue.trim() === "") { 
      // inValidRequest
      setIsPosted(true); 
      setIsPostting(false);
      return;
    }

    setIsPostting(true);

    addDoc(collection(db, "feedback"), {

      評価: feedbackValue,
      場所: window.location.href,
      コメント: inputValue,
      timestamp: serverTimestamp(),

    }).then(res => {
    // fetch('asd').then(res => {

    }).catch(err => {
      console.log(err);
      setSnackMsg("送信失敗！")

    }).finally(() => {
      setIsPosted(true); 
      setIsPostting(false);
    })
  }





  return (
    <>
    <PaperBox sx={{px: 3, py: 3, height: 'auto'}}>
      <Typography variant="subtitle2" component="h6" sx={{textAlign: "center"}}>
        このページは役に立ちましたか？
      </Typography>

      <Box  sx={{textAlign: "center"}}>
        <FeedIcon type="good" onChange={setFeedbackValue} disabled={disabled} />
        <FeedIcon type="bad" onChange={setFeedbackValue} disabled={disabled} />
      </Box>

      <Fade in={feedbackValue !== "" && !isPosted} timeout={600}
        className={(feedbackValue === "" ||( isPosted )) ? "d-none" : ""}
      >
        <Box sx={{mx: [0, 6], my: 2 }}>

        <Typography variant="subtitle1" component="h5" sx={{fontWeight: "600", my: .5}}>
          ご意見ありがとうございます！
        </Typography>
        
        <Typography variant="body2" component="p" sx={{my: .5}}>
          もしよろしければ、あわせてフィードバックや要望などをご入力ください。
        </Typography>

        <OutlinedInput
          multiline
          rows={4}
          fullWidth
          disabled={isPostting}
          sx={{ 
            mt: 2,
            "& legend": {
              display: "none",
            }
          }}
          onChange={handleInputValue}
        />
        <Box sx={{display: "flex" ,flexDirection: 'row-reverse', my: 2 }}>
          {
            !isPostting ?
            ( <Button 
              variant="contained"
              color="secondary"
              endIcon={<SendIcon />}
              onClick={postFeedBack}
            >
              送信
            </Button> ) : (
              <LoadingButton loading variant="outlined">
                Submit
              </LoadingButton>
            )
          }
        </Box>

        </Box>
      </Fade>
    </PaperBox>

    <Snackbar
      open={snackOpen}
      autoHideDuration={6000}
      message={snackMsg}
      sx={{ maxWidth: [256, 356] , bottom: 32}}
    />
    </>
  )
}