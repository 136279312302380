import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


export default function (props) {

  const placeholder = props.placeholder ? props.placeholder : "キーワード検索";

  const handleSubmit = e => {
    e.preventDefault();
    props.onSubmit(e)
  }

  return (
    <Box sx={{px: 3, display: "flex", ...props.sx }} component="form" onSubmit={handleSubmit}>
      <InputBase
        sx={theme => ({
          ml: 1, flex: 1,
          // backgroundColor: theme.palette.background.paper,
          backgroundColor: theme.palette.grey[300],
          color: "rgba(0,0,0,0.7)",
          px: 2, py:.5 , borderRadius: "4px",
          flex: 1,
          fontSize: 16,
        })}
        value={props.value}
        onChange={props.onChange}
        placeholder={placeholder}
        inputProps={{
          inputMode: "search"
        }}
      />

      <Button 
        variant="contained"
        color="secondary"
        size='large'
        type='submit'
        endIcon={<SearchIcon />}
        sx={{
          mx: 1,
          py: 1,
          px: 1
        }}
      >
        検索

      </Button>
      
    </Box>
  )
}